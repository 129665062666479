<template>
  <div class="container">
    <Header title="设备详情" color="#666" />
    <van-cell-group class="mgt10">
      <van-cell title="设备名称：" title-class="title" :value="detail.name" />
      <van-cell
        title="设备编号："
        title-class="title"
        :value="detail.equipmentNum"
      />
      <van-cell
        title="设备类型："
        title-class="title"
        :value="detail.equipmentType"
      />
      <van-cell title="设备ICCID：" title-class="title" :value="detail.iccid" />
      <van-cell
        title="站点名称："
        title-class="title"
        :value="detail.siteName"
      />
      <van-cell title="状态：" title-class="title">
        <template>
          <van-tag :type="getByStatus(detail.netStatus)">{{
            detail.netStatus
          }}</van-tag>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="mgt10">
      <van-cell
        title="管理人员："
        title-class="title"
        :value="detail.createUser"
      />
      <van-cell
        title="创建时间："
        title-class="title"
        :value="parseTime(detail.createTime)"
      />
    </van-cell-group>

    <van-cell-group class="mgt10" v-if="mapImg">
      <van-cell title="位置：" title-class="title">
        <template #label>
          <!-- <img class="map" :src="mapImg" @click="showPopup = true" /> -->
          <img class="map" :src="mapImg" />
        </template>
      </van-cell>
    </van-cell-group>

    <!-- <van-cell-group class="mgt10">
      <van-cell title="图片">
        <template #label>
          <van-image
            v-for="(item, index) in imglist"
            class="mgr20"
            :key="index"
            width="2.5rem"
            height="2.5rem"
            :src="item"
            @click="showImagePreview(imglist, index)"
          />
        </template>
      </van-cell>
    </van-cell-group> -->

    <van-cell-group class="mgt10">
      <van-cell title="简介：" title-class="title">
        <template #label>
          <div v-html="detail.content"></div>
        </template>
      </van-cell>
    </van-cell-group>

    <!-- 上报按钮 -->
    <AddButton
      name="实时工况"
      :to="{ path: '/device/monitor', query: { id: id } }"
    />
    <!-- 选择导航 -->
    <van-action-sheet
      v-model="showPopup"
      :actions="actions"
      @select="onSelect"
    />
  </div>
</template>
<script>
import { getDeviceDetail } from "@/api/device";
import {
  Toast,
  Cell,
  CellGroup,
  Image,
  ImagePreview,
  Tag,
  ActionSheet
} from "vant";
import { bMapTransqqMap } from "@/utils";
import AddButton from "@/components/addButton";
import Header from "@/components/header";
export default {
  name: "DeviceDetail",
  components: {
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [ActionSheet.name]: ActionSheet,
    [ImagePreview.Component.name]: ImagePreview.Component,
    AddButton,
    Header
  },
  data() {
    return {
      loading: false,
      id: "",
      detail: {
        lat: "",
        lng: "",
        netStatus: ""
      },
      showPopup: false,
      actions: [
        { id: "baidu", name: "百度地图" },
        { id: "qq", name: "腾讯地图" },
        { id: "gaode", name: "高德地图" }
      ],
      imglist: [
        "http://42.120.20.41:8389/nvsh/components/upload/preview?downloadId=1001848662361374720",
        "http://42.120.20.41:8389/nvsh/components/upload/preview?downloadId=1001848662361374720",
        "http://42.120.20.41:8389/nvsh/components/upload/preview?downloadId=1001848662361374720",
        "http://42.120.20.41:8389/nvsh/components/upload/preview?downloadId=1001848662361374720"
      ]
    };
  },
  computed: {
    mapImg() {
      let map = null;
      if (this.detail.lat) {
        map = `https://api.map.baidu.com/staticimage/v2?ak=${process.env.VUE_APP_MAP_KEY}&mcode=666666&center=${this.detail.lng},${this.detail.lat}&width=375&height=200&zoom=16&markers=${this.detail.lng},${this.detail.lat}`;
      }
      return map;
    },
    baiduMapNav() {
      let map = null;
      if (this.detail.lat) {
        map = `http://api.map.baidu.com/marker?location=${this.detail.lat},${this.detail.lng}&title=${this.detail.siteName}&content=${this.detail.siteName}&output=html`;
      }
      return map;
    },
    qqMapNav() {
      let map = null;
      if (this.detail.lat) {
        const format = bMapTransqqMap(this.detail.lng, this.detail.lat);
        map = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${format.lat},${format.lng};title:${this.detail.siteName};addr:${this.detail.siteName}`;
      }
      return map;
    },
    gaodeMapNav() {
      let map = null;
      if (this.detail.lat) {
        const format = bMapTransqqMap(this.detail.lng, this.detail.lat);
        map = `http://uri.amap.com/marker?position=${format.lng},${format.lat}&name=${this.detail.siteName}&coordinate=gaode&callnative=1`;
      }
      return map;
    }
  },
  created() {
    this.parseTime = this.utils.parseTime;
    const id = this.$route.query.id;
    if (!id) {
      return Toast.fail("参数错误！");
    }
    this.id = id;
    this.getDeviceDetail();
  },
  mounted() { },
  updated() { },
  methods: {
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.showPopup = false;
      if (item.id == "baidu") {
        this.location(this.baiduMapNav);
      } else if (item.id == "qq") {
        this.location(this.qqMapNav);
      } else if (item.id == "gaode") {
        this.location(this.gaodeMapNav);
      }
      console.log(item);
    },
    getDeviceDetail() {
      Toast.loading("加载中...");
      const id = this.id;
      getDeviceDetail({ id: id })
        .then(res => {
          this.detail = res.data;
          if (res.data.files) {
            const fileList = JSON.parse(res.data.files);
            this.imglist = fileList.map(item => {
              return process.env.VUE_APP_BASE_API + item.id;
            });
          }
          console.log(this.detail);
          Toast.clear();
        })
        .catch(err => {
          console.log(err);
        });
    },
    //图片预览
    showImagePreview(imglist, index) {
      ImagePreview({
        images: imglist,
        startPosition: index
      });
    },
    //导航
    location(url) {
      location.href = url;
    },
    //根据类型获取状态
    getByStatus(status) {
      if (status == "离线") {
        status = 0;
      } else if (status == "在线") {
        status = 1;
      } else {
        status = 2;
      }
      const statusArr = ["danger", "success", "warning"];
      return statusArr[status];
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: 60px;
  .map {
    width: 345px;
    height: 200px;
  }
  .title {
    width: 100px;
  }
}
</style>