<template>
  <div>
    <div class="fixed-button">
      <van-button class="btn" size="large" block color="#08a9f7" :to="to">{{
        name
      }}</van-button>
    </div>
  </div>
</template>
<script>
import { Button } from "vant";
export default {
  name: "FixedButton",
  components: {
    [Button.name]: Button
  },
  props: {
    name: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {

  }
};
</script>
<style lang="scss" scoped>
.fixed-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>